/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;
@use '@syspons/ngs-navigation/src/lib/side-nav/side-nav-theme' as side-nav;

// @use '@syspons/ngs-entity/src/lib/style/entity-theme' as entity;
@import '@syspons/ngs-storage/styles/cookieconsent';
@import '@syspons/ngs-theme/styles/theme';
@import './styles/colors';
@import './styles/theme';

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: mat.font-family(mat.define-typography-config());
}

// Include all theme styles for the components.
@include mat.all-legacy-component-themes($theme);
@include side-nav.theme($theme);
@include mtx.all-component-themes($theme);
